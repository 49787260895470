@tailwind base;

@tailwind components;

@tailwind utilities;

body {
    margin: 0;
    /*font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',*/
    /*'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',*/
    /*sans-serif;*/
    /*font-family: 'Open Sans', Arial, sans-serif;*/
    font-family: 'Roboto', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    /*--tw-bg-opacity: 1;*/
    /*background-color: rgb(209 213 219 / var(--tw-bg-opacity));*/
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.leaflet-container {
    height: 150px !important;
    width: 100% !important;
    z-index: 0;
}

.leaflet-control-attribution.leaflet-control {

    display: none;
}


:root {
    --map-tiles-filter: brightness(0.6) invert(1) contrast(3) hue-rotate(200deg) saturate(0.3) brightness(0.7);
}

@media (prefers-color-scheme: dark) {
    .map-tiles {
        filter: var(--map-tiles-filter, none);
    }
}

.grecaptcha-badge {
    width: 70px !important;
    overflow: hidden !important;
    transition: all 1.3s ease !important;
    right: 4px !important;
    top: 4px !important;
}

.main-logo {
    max-width: 200px;
    max-height: 42px;
    object-fit: contain;
}
